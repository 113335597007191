import { render, staticRenderFns } from "./channel_state_sync_add_upd.vue?vue&type=template&id=04dd9b75&scoped=true"
import script from "./channel_state_sync_add_upd.vue?vue&type=script&lang=js"
export * from "./channel_state_sync_add_upd.vue?vue&type=script&lang=js"
import style0 from "./channel_state_sync_add_upd.vue?vue&type=style&index=0&id=04dd9b75&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04dd9b75",
  null
  
)

export default component.exports